<template>
  <div class="container mescroll-touch">
    <div class="header">
      <div class="searchBox" @click="toSearch()">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
      </div>
      <div class="header_title">
        <div class="columnList">
          <div class="titleItem" :class="{'titleItem_active': columnTcode == item.TCode}" v-for="item in columnList" :key="item.Code" @click="changeTitleMenu(item.TCode, item.Code)">
            <span>{{item.DisplayName}}</span>
          </div>
        </div>
        <div class="filterBtn" @click="showCodePicker = true">
          <span>{{ColumnSecondLeveName ? ColumnSecondLeveName : '全部'}}</span>
          <van-icon name="arrow-down" />
        </div>
        <van-popup v-model="showCodePicker" round position="bottom" :style="{ height: '40%' }" @open="onActivityFilteringPickerOpen">
          <div class="pickerContent">
            <div class="van-picker__toolbar toolbar">
              <button type="button" class="van-picker__cancel" @click="onActivityFilteringCancel">取消</button>
              <button type="button" class="van-picker__confirm" @click="onActivityFilteringConfirm">确认</button>
            </div>
            <div class="picker_content">
              <div class="pickerBox">
                <div class="picker_block" :class="{'activeBg': TempColumnSecondLeveTcode == item.TCcode}" v-for="(item, index) in ColumnSecondLevelList" :key="index" @click="selectCode(item.TCcode, item.Name)">{{item.Name}}</div>
              </div>
            </div>
          </div>
        </van-popup>
      </div>
    </div>
    <div class="mescrollBox">
      <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
        <div class="titleBox">
          <div class="title">
            <img class="screenIcon" src="@/assets/images/new_home_icon_alt@2x.png" />
            <span>最新推荐</span>
          </div>
          <div class="more" @click="toList()">
            <span>更多</span>
            <img src="@/assets/images/more_yellow.png" />
          </div>
        </div>
        <div class="recommendBox" v-if="recommendList.length > 0">
          <div class="recommendItem" v-for="(item, index) in recommendList" :key="index" @click="toContentPage(item.Id, item.Type)">
            <div class="item_img">
              <img class="item_cover" :src="item.Thumbnail" />
              <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
            </div>
            <div class="item_content">
              <span class="item_title">{{item.Title}}</span>
              <div class="item_flexbox">
              <p class="item_time">{{item.yyyymmdd}}</p>
              <div class="item_nums">
                <div class="nums">
                  <img class="watchIcon" src="@/assets/images/watch_yellow.png" />
                  <span>{{item.WatchCount}}</span>
                </div>
                <div class="nums">
                  <img class="likeIcon" src="@/assets/images/like_yellow.png" />
                  <span>{{item.LikeCount}}</span>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="titleBox">
          <div class="title">
            <img class="screenIcon" src="@/assets/images/new_home_icon_alt@2x.png" />
            <span>最新上线</span>
          </div>
        </div>
        <div class="list" id="dataList">
          <div class="item" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
            <div class="item_img">
              <img class="item_cover" :src="item.Thumbnail" />
              <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
            </div>
            <div class="item_content">
              <span class="item_title">{{item.Title}}</span>
              <div class="item_flexbox">
              <p class="item_time">{{item.yyyymmdd}}</p>
              <div class="item_nums">
                <div class="nums">
                  <img class="watchIcon" src="@/assets/images/watch_yellow.png" />
                  <span>{{item.WatchCount}}</span>
                </div>
                <div class="nums">
                  <img class="likeIcon" src="@/assets/images/like_yellow.png" />
                  <span>{{item.LikeCount}}</span>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </mescroll-vue>
    </div>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  name: 'CaseZoneIndex',
  data() {
    return {
      columnList: [],
      columnTcode: '',
      columnCode: '',
      recommendList: [],
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata"></p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        }
			},
      showCodePicker: false,
      ColumnSecondLevelList: [],
      ColumnSecondLeveTcode: '',
      ColumnSecondLeveName: '',
      TempColumnSecondLeveTcode: '',
      TempColumnSecondLeveName: '',
      TempCodename: '',
      isGetColumn: false
    }
  },
  computed: {
    codename() {
      return this.$route.query.codename;
    }
  },
  created() {
    this.TempCodename = this.codename;
    this.getColumn();
  },
  components: {
    MescrollVue
  },
  methods: {
    // 获取栏目
    getColumn() {
      this.$axios.get('/Api/api/Web/Article/GetCode?code=cnvblzq').then(res => {
        let data = res.Data;
        if(data.length > 0) {
          this.columnTcode = data[0].TCode;
          this.columnCode = data[0].Code;
          this.columnList = data;
          this.getColumnSecondLevel();
        }
      })
    },
    getColumnSecondLevel() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=2&pcode=' + this.columnCode).then(res => {
        this.ColumnSecondLevelList = res.Data;
        if(this.TempCodename) {
          const filteredArr = this.ColumnSecondLevelList.filter(obj => obj.Name == this.TempCodename);
          this.ColumnSecondLeveTcode = filteredArr[0].TCcode;
          this.ColumnSecondLeveName = filteredArr[0].Name;
          this.TempColumnSecondLeveTcode = filteredArr[0].TCcode;
          this.TempColumnSecondLeveName = filteredArr[0].Name;
        }
        this.getRecommendList();
        this.isGetColumn = true;
        this.list = [];
        this.mescroll.resetUpScroll() // 刷新列表数据
      });
    },
    // 获取最新推荐列表数据
    getRecommendList() {
      this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
        Code: 'cnvblzq',
        SubjectTCode: this.ColumnSecondLeveTcode ? this.ColumnSecondLeveTcode : this.columnTcode,
        ModuleType: 2,
        PageIndex: 1,
        PageSize: 2
      }).then((res) => {
        if(res.Data.List) {
          this.recommendList = res.Data.List;
        }
      }).catch((e) => {
      })
    },
    // 获取最新上线列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          Code: 'cnvblzq',
          SubjectTCode: this.ColumnSecondLeveTcode ? this.ColumnSecondLeveTcode : this.columnTcode,
          ModuleType: 7,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = [];
          if(res.Data.List) {
            arr = res.Data.List;
          }
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      if(this.isGetColumn) {
        this.getList(page.num, page.size);
      }
		},
    changeTitleMenu(tcode, code) {
      this.columnTcode = tcode;
      this.columnCode = code;
      this.getColumnSecondLevel();
      // this.recommendList = [];
      // this.list = [];
      // this.getRecommendList();
      // this.mescroll.resetUpScroll() // 刷新列表数据
    },
    selectCode(tcode, name) {
      if(this.TempColumnSecondLeveTcode == tcode) {
        this.TempColumnSecondLeveTcode = '';
        this.TempColumnSecondLeveName = '';
      } else {
        this.TempColumnSecondLeveTcode = tcode;
        this.TempColumnSecondLeveName = name;
      }
    },
    onActivityFilteringPickerOpen() {
      if(this.ColumnSecondLeveName) {
        const filteredArr = this.ColumnSecondLevelList.filter(obj => obj.Name == this.ColumnSecondLeveName);
        this.TempColumnSecondLeveTcode = filteredArr[0].TCcode;
        this.TempColumnSecondLeveName = filteredArr[0].Name;
      } else {
        this.TempColumnSecondLeveTcode = '';
        this.TempColumnSecondLeveName = '';
      }
    },
    onActivityFilteringCancel() {
      this.showCodePicker = false;
    },
    onActivityFilteringConfirm() {
      this.showCodePicker = false;
      this.ColumnSecondLeveTcode = this.TempColumnSecondLeveTcode;
      this.ColumnSecondLeveName = this.TempColumnSecondLeveName;
      this.TempCodename = this.TempColumnSecondLeveName;
      this.recommendList = [];
      this.list = [];
      this.getRecommendList();
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    toSearch() {
      this.$router.push({
        path: 'search',
        query: {
          code: 'cnvblzq'
        }
      });
    },
    toList() {
      this.$router.push({
        path: 'caseZoneList',
        query: {
          tcode: this.columnTcode,
          codename: this.TempCodename,
          type: 2
        }
      });
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url('../../assets/images/header_bg2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F8F8F8;
  .header {
    padding: 15px 15px 10px;
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      height: 35px;
      line-height: 35px;
      border-radius: 35px;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      padding: 0 15px;
      margin-bottom: 10px;
      .searchIcon {
        width: 15px;
        height: 15px;
      }
      .searchInput {
        flex: 1;
        margin-left: 8px;
        border: none;
        color: #999999;
      }
    }
    .header_title{
      display: flex;
      justify-content: space-between;
      .columnList {
        display: flex;
        align-items: center;
      }
      .titleItem {
        position: relative;
        padding: 0 10px;
        font-size: 15px;
        color: #FFFFFF;
        span {
          display: inline-block;
          width: 100%;
        }
      }
      .titleItem_active::after {
        content: "";
        position: absolute;
        left: 37%;
        width: 26%;
        height: 3px;
        border-radius: 3px;
        background-color: #FFFFFF;
        bottom: -7px;
      }
      .filterBtn {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        font-size: 14px;
        float: right;
        span {
          margin-right: 5px;
        }
      }
    }
  }
  .mescrollBox {
    position: fixed;
    width: 100%;
    top: 95px;
    bottom: 0;
    height: auto;
    border-radius: 12px 12px 0 0;
    background-color: #F8F8F8;
  }
  .mescroll {
    padding: 15px 15px 0;
    box-sizing: border-box;
  }
  
  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .title {
      width: auto;
      display: flex;
      align-items: center;
      height: 30px;
      border-radius: 30px;
      padding: 0 10px;
      box-sizing: border-box;
      font-size: 14px;
      background-color: $themeColor;
      color: #FFFFFF;
      img {
        display: block;
        width: 16px;
        height: 14px;
        margin-right: 4px;
      }
    }
    .more{
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $themeColor;
      img {
        width: 14px;
        height: 14px;
        margin-left: 4px;
      }
    }
  }
  .recommendBox {
    display: flex;
    margin-bottom: 10px;
    min-height: 50px;
    .recommendItem {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      width: calc(calc(100% / 2) - 5px);
      border-radius: 6px;
      background-color: #FFFFFF;
      overflow: hidden;
      .item_img {
        position: relative;
        .item_cover {
          display: block;
          width: 100%;
        }
        .playIcon {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 30px;
          height: 30px;
        }
      }
      .item_content {
        padding: 5px 8px 8px;
        .item_title {
          height: 35px;
          font-size: 13px;
          line-height: 17px;
          color: #333333;
          overflow: hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          word-wrap: break-word;
        }
        .item_time {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
        }
        .item_label {
          display: flex;
          flex-wrap: wrap;
          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
            padding: 0 6px;
            margin-right: 6px;
            margin-bottom: 5px;
            background-color: rgba(255, 73, 98, 0.15);
            font-size: 11px;
            color: #CA001B;
          }
        }
        .item_nums {
          display: flex;
          justify-content: flex-end;
          .nums {
            display: flex;
            align-items: center;
            .watchIcon {
              width: 14px;
              margin: 0 2px 0 6px;
            }
            .likeIcon {
              width: 11px;
              margin: 0 2px 0 6px;
            }
            span {
              font-size: 11px;
              color: #666666;
            }
          }
        }
      }
    }
    .recommendItem:not(:last-child) {
      margin-right: 10px;
    }
  }
  .list {
    .item {
      position: relative;
      display: flex;
      background-color: #FFFFFF;
      border-radius: 6px;
      overflow: hidden;
      .item_img {
        width: 50%;
        position: relative;
        .item_cover {
          display: block;
          width: 100%;
        }
        .playIcon {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 30px;
          height: 30px;
        }
      }
      .item_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        width: 0;
        padding: 8px;
        box-sizing: border-box;
        .item_title {
          height: 35px;
          font-size: 13px;
          line-height: 17px;
          color: #333333;
          overflow: hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          word-wrap: break-word;
        }
        .item_time {
          font-size: 12px;
          color: #999999;
          // line-height: 22px;
        }
        .item_nums {
          // position: absolute;
          // bottom: 8px;
          display: flex;
          justify-content: flex-start;
          .nums {
            display: flex;
            align-items: center;
            .watchIcon {
              width: 14px;
              margin: 0 2px 0 0;
            }
            .likeIcon {
              width: 11px;
              margin: 0 2px 0 6px;
            }
            span {
              font-size: 11px;
              color: #666666;
            }
          }
        }
      }
    }
    .item:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .picker_content {
    padding: 12px 6px;
    .pickerBox {
      display: flex;
      flex-wrap: wrap;
    }
    .picker_block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(calc(100% / 3) - 12px);
      height: 36px;
      text-align: center;
      border: 1px solid rgba(153, 153, 153, 0.5);
      box-sizing: border-box;
      margin: 6px;
      border-radius: 4px;
      color: #333333;
      font-size: 14px;
    }
    .activeBg {
      border: 1px solid #FF6900;
      color: #FF6900;
    }
  }
}
</style>